/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI to display when an error occurs
      return (
        <div className='flex items-center justify-center h-screen bg-gray-100 flex-col p-8'>
          <h1 className='font-bold text-lg bg-slate-100'>
            Something went wrong!
          </h1>
          <p>
            {this.state.error &&
              this.state.error.toString()}
          </p>
          <p>
            {this.state.errorInfo &&
              this.state.errorInfo.componentStack}
          </p>
        </div>
      );
    }

    // Render the child components if no error occurred
    return this.props.children;
  }
}

export default ErrorBoundary;
