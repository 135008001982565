import RtkQueryService from '@lok/services/RtkQueryService';

import { transformErrorResponse } from '../api';

const logService = RtkQueryService.injectEndpoints({
  endpoints: (builder) => ({
    createLog: builder.mutation({
      query: (data) => ({
        url: '/v1/log/',
        method: 'POST',
        data
      }),
      transformErrorResponse
    })
  })
});

export const { useCreateLogMutation } = logService;
