import { combineReducers } from '@reduxjs/toolkit';

import user from './userSlice';
import sessionSlice from './sessionSlice';

const reducer = combineReducers({
  session: sessionSlice,
  user
});

export default reducer;
