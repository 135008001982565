import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';

import store from '../store';
// import { refreshToken } from './auth';
import deepParseJson from '../utils/deepParseJson';
import { signOutSuccess } from '../store/auth/sessionSlice';

const unauthorizedCode = [401];
export const TOKEN_TYPE = 'Bearer ';
export const PERSIST_STORE_NAME = 'admin';
export const REQUEST_HEADER_AUTH_KEY = 'Authorization';

export const API_URL = process.env.REACT_APP_API_URL;

const BaseService = axios.create({
  timeout: 60000,
  baseURL: API_URL
});

BaseService.interceptors.request.use(
  async (config) => {
    const rawPersistData = localStorage.getItem(
      PERSIST_STORE_NAME
    );
    const persistData = deepParseJson(rawPersistData);

    let accessToken = persistData?.auth?.session?.token;

    if (!accessToken) {
      const { auth } = store.getState();
      accessToken = auth.session.token;
    }

    // const currentDate = new Date();

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers[REQUEST_HEADER_AUTH_KEY] =
        `${TOKEN_TYPE}${accessToken}`;

      // const decodedToken = jwtDecode(accessToken);
      // const rememberMe = localStorage.getItem('rememberMe');
      // const isExpiringSoon =
      //   decodedToken.exp * 1000 < currentDate.getTime();

      // if (isExpiringSoon && rememberMe) {
      //   await store.dispatch(
      //     refreshToken({
      //       refresh: persistData.auth.session.refreshToken
      //     })
      //   );
      // }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (
      response &&
      unauthorizedCode.includes(response.status)
    ) {
      store.dispatch(signOutSuccess());
    }

    return Promise.reject(error);
  }
);

export default BaseService;
