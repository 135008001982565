import { combineReducers } from 'redux';

import auth from './auth';
import theme from './theme/themeSlice';
import RtkQueryService from '../services/RtkQueryService';

const staticReducers = {
  theme,
  auth,
  [RtkQueryService.reducerPath]: RtkQueryService.reducer
};

export const rootReducer =
  (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
      ...staticReducers,
      ...asyncReducers
    });
    return combinedReducer(state, action);
  };

export const middlewares = [RtkQueryService.middleware];
