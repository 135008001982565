import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  layout: {
    type: 'modern',
    sideNavCollapse: true
  }
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setSideNavCollapse: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.layout = {
        ...state.layout,
        ...{ sideNavCollapse: action.payload }
      };
    }
  }
});

export const { setSideNavCollapse } = themeSlice.actions;

export default themeSlice.reducer;
