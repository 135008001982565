import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';

import { rootReducer, middlewares } from './rootReducers';

const persistConfig = {
  key: 'admin',
  keyPrefix: '',
  storage,
  whitelist: ['auth', 'locale']
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer()),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ]
      }
    }).concat(middlewares)
});

store.asyncReducers = {};

export const persistor = persistStore(store);

export function injectReducer(key, reducer) {
  if (store.asyncReducers) {
    if (store.asyncReducers[key]) {
      return false;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(
      persistReducer(
        persistConfig,
        rootReducer(store.asyncReducers)
      )
    );
  }
  persistor.persist();
  return store;
}

export default store;
