import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo, lazy, Suspense, useEffect } from 'react';

import GlobalLoader from './Loading';
import useAuth from '../../hooks/useAuth';
import ScrollToTopWrapper from './ScrollWrapper';
import useIsMounted from '../../hooks/useIsMounted';

const Layout = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const location = useLocation();

  const { authenticated } = useAuth();

  useEffect(() => {
    const NO_REDIRECT = [
      '/forgot-password',
      '/password/reset/confirm/',
      '/login'
    ];

    if (
      isMounted() &&
      !authenticated &&
      !NO_REDIRECT.includes(location.pathname)
    ) {
      navigate('/login');
      localStorage.removeItem('admin');
    }
  }, [isMounted, authenticated]);

  const AppLayout = useMemo(() => {
    if (authenticated) {
      return lazy(() => import('./AuthLayout'));
    }
    return lazy(() => import('./NotAuthLayout'));
  }, [authenticated]);

  return (
    <Suspense
      fallback={
        <div className='flex flex-auto flex-col h-[100vh]'>
          <GlobalLoader />
        </div>
      }
    >
      <ScrollToTopWrapper>
        <AppLayout />
      </ScrollToTopWrapper>
    </Suspense>
  );
};

export default Layout;
