import store from '../store';
import { signOutSuccess } from '../store/auth/sessionSlice';

export const API_URL = process.env.REACT_APP_API_URL;
export const PERSIST_STORE_NAME = 'admin';
const unauthorizedCode = [401];

export const transformErrorResponse = (response) => {
  if (
    response &&
    unauthorizedCode.includes(response.status)
  ) {
    store.dispatch(signOutSuccess());
  }

  if (response && response.status === 404) {
    window.location.href = '/404';
  }

  return response;
};
