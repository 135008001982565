/* eslint-disable import/no-cycle */
import { createApi } from '@reduxjs/toolkit/query/react';

import BaseService from './BaseService';

const axiosBaseQuery = () => async (request) => {
  try {
    const response = await BaseService(request);
    return response;
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    };
  }
};

const RtkQueryService = createApi({
  reducerPath: 'rtkApi',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({})
});

export default RtkQueryService;
