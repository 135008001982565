import { GridLoader } from 'react-spinners';

const GlobalLoader = () => {
  return (
    <div className='flex items-center justify-center h-screen m-auto'>
      <GridLoader color='#000000' size={15} />
    </div>
  );
};
export default GlobalLoader;
