/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  signedIn: false,
  token: null
};

const sessionSlice = createSlice({
  name: `auth/session`,
  initialState,
  reducers: {
    signInSuccess(state, action) {
      state.signedIn = true;
      state.token = action.payload;
    },
    signOutSuccess(state) {
      state.signedIn = false;
      state.token = null;
    }
  }
});

export const { signInSuccess, signOutSuccess } =
  sessionSlice.actions;

export default sessionSlice.reducer;
